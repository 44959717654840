
.airpumpHeading,
.groundpumpHeading {
    text-transform: uppercase;
    text-align: center;
}

.groundpumpFiltering,
.airpumpFiltering,
.groundpumpHeading,
.airpumpHeading {
    border-bottom-width: 0.08em;
    border-bottom-style: solid;
}

.groundpumpFiltering,
.groundpumpSelection,
.groundpumpHeading {
    border-right-width: 0.08em;
    border-right-style: solid;
}

.groundpumpSelection,
.airSelection {
    padding: 1em 0 1em 1em;
}

.airPreviewContainer,
.groundPreviewContainer,
.bothPreviewsContainer,
.airPreview,
.groundPreview {
    font-size: 1em;
}

.noSelectionPreviewContainer {
    font-size: 1em;
}

.airPreviewContainer {

    .groundpumpSelection {
        border-bottom-width: 0.08em;
        border-bottom-style: solid;
    }
}
.groundPreviewContainer {

    .airSelection {
        border-bottom-width: 0.08em;
        border-bottom-style: solid;
    }
}
.bothPreviewsContainer {

    .groundPreview,
    .airPreview {
        border-top-width: 0.08em;
        border-top-style: solid;
    }
    .groundPreview {
        padding-right: 0.25em;
    }
    .airPreview {
        padding-left: 0.25em;
    }
}

.singlePumpView {
    .groundpumpFiltering,
    .airpumpFiltering,
    .groundpumpHeading,
    .airpumpHeading {
        border-bottom-width: 0em;
    }
    
    .groundpumpFiltering,
    .groundpumpSelection,
    .groundpumpHeading {
        border-right-width: 0;
    }
    
    .airPreviewContainer {    
        .groundpumpSelection {
            border-bottom-width: 0em;
        }
    }
    .groundPreviewContainer {
        .airSelection {
            border-bottom-width: 0em;
        }
    }
}

.airPreview,
.groundPreview {
    padding-top: 0.5em;
}

.singlePumpView {
    .airPreview,
    .groundPreview {
        border-top-width: 0em;        
    }
}

.pumpDescription {
    font-size: 1em;
}

.dimensioningContainer {
    padding: 1em 1em 0em 1em;

}

.noHeatpumpWarning {
    width: 100%;
}

.technicalPreview {
    svg {
        margin-right: 0.25em;
        display: block;
        margin: 0 auto;
    }
    .labels {
        font-size: 0.75em;
        text-align: center;
        padding-top: 0.5em;
    }
    .values {
        text-align: center;
        font-size: 0.8em;
        font-weight: 500;
        height: 3em;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    
    .svgIcon img,
    .subicon img {
        width: 100%;
        max-width: 1.95em;

    }
    .technicalCard {
        border-radius: 0.25em;
        box-shadow: 0px 2px 1px -1px rgba(77, 77, 77, 0.2),0px 1px 1px 0px rgba(77,77,77,0.14),0px 1px 3px 0px rgba(77,77,77,0.12);
    }
    .subIconContainer {
        position: relative;
        top: 0;
        left: 0;
        display: inline-block;
    }
    .subIcon {
        position: absolute;
        bottom: -18%; /* -18 */
        right: -30%; /* -30*/
        font-size: 0.45em;
        background: #fff;
        border: 0.08em solid rgba(0,0,0,03);
        border-radius: 2.5em;
        width: 2.5em;
        height: 2.5em;
        display: flex;
        justify-content: center;
        align-items: center;
        
        
        .circulationPumpHeatIcon {
            width: 0.7em;            
            height: 0.7em;
        }
    }
}