
.navigationButtons {
    
    margin: 0em 0 0 0;
    
    .divider {
        margin: 1em 0;
    }
    .previous {
        padding-left: 1em;
    }
    .next {
        text-align: right;
        padding-right: 1em;
    }
}
.generateButtons {
    text-align: center;
}
