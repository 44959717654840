.unit {
    font-weight: 600;
    vertical-align: top;
    display: inline-block;
    padding-top: 0.25em;
    padding-left: 0em;
    font-weight: 500!important;
    font-size: 1.1em!important;
}
.unitBC {
    font-weight: 600;
    
    display: inline-block;
    
    padding-left: 0em;
    font-weight: 500!important;
    font-size: 1em!important;
}
.label {
    vertical-align: top;
    display: inline-block;
    padding-top: 0.25em;
    padding-left: 0.5em;
}
.extraHeatingSource {
    margin-top: 0.25em;
}