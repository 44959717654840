.checkboxContainerCard {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
.checkboxCard {
    margin: 0 0 0em 0;
}

.technicalPreview {
    svg {
        margin-right: 0.25em;
        display: block;
        margin: 0 auto;
    }
    .labels {
        font-size: 0.75em;
        text-align: center;
        padding-top: 0.5em;
    }
    .values {
        text-align: center;
        font-size: 0.8em;
        font-weight: 500;
        height: 3em;
        

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    .technicalCard {
        border-radius: 0.25em;
        box-shadow: 0px 2px 1px -1px rgba(77, 77, 77, 0.2),0px 1px 1px 0px rgba(77,77,77,0.14),0px 1px 3px 0px rgba(77,77,77,0.12);
    }
}