.buttons {
    text-align: right;
    padding-bottom: 1.5em;
}
.LoginIMG {
    display: flex;
    align-items: center;
    
    img {
        width: 100%;
        height: 100%;
    }
}
.content {
    max-width: 1000px;
    margin-bottom: 150px;

    .whatIs {
        text-align: center;
        align-self: center;
        
        p {
            margin-top: 1em;
        }
    }
    .Useful {
        text-align: left;
        align-self: center;

        p {
            margin-top: 1em;
        }
        svg {
            position: relative;
            top: 0.25em;
            align-self: center;
            margin-right: 0.5em;
        }
    }
    .Spacer {
        height: 3em;
    }
    
}
