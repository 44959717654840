.buttons {
    padding-top: 1em;
    text-align: right;
    margin-bottom: 1em;
    padding-right: 10px;
}

.dataGrid {
    border: 0 !important;
    width: 100%;
    margin-bottom: 80px;
    
    :global(.MuiDataGrid-columnHeaders) {
        background: #B8D3E6;
        color: #000000;  
    }
    :global(.MuiDataGrid-columnHeaderTitle)  {
        font-size: 1.25em!important;
    }
    :global(.MuiDataGrid-withBorderColor) {
        border-color: transparent!important;
    }
    :global(.MuiDataGrid-row) {
        background: #ffffff;
        transition: all 0.3s;
    }
    :global(.MuiDataGrid-row:nth-child(odd)) {
        background: #f4f4f4;
    }
    :global(.MuiDataGrid-row:nth-child(even)) {
        background: #EAF2F8;
    }
    :global(.MuiDataGrid-withBorderColor) {
        border: 0 !important;
    }
    :global(.MuiDataGrid-row:hover) {
        background: #e5e5e5!important;
    }
    :global(.MuiDataGrid-actionsCell svg) {
        width: 1.25em !important;
        height: 1.25em !important;
    }
}