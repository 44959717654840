.container {        
    position: relative;    
    max-width: 498px;
    margin: 0 auto;

    &[data-haslogo=false] .deleteButtonContainer{
        display: none;
    }

    .deleteButtonContainer {
        position: absolute;
        top: 0;
        right: 0;
        opacity: 0;

        transition: 0.3s opacity;
    }

    &:hover .deleteButtonContainer {
        opacity: 1;
    }

    .inner {
        aspect-ratio: 498 / 186;
        border: 1px solid #333;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 0.5em;
        cursor: pointer;
    
        p {
            margin: 0;
        }
    
        img {
            width: 100%;
        }
    }
}