@import 'AppVariables.module.scss';

body {
    background-image: linear-gradient(
        180deg, 
        #B8D3E6 10%, /* B8D3E6 | EBEFF1*/
        #fff 100%
    );
    font-family: Montserrat, Roboto, Arial, Helvetica, sans-serif;
}



.container {     
    display: flex;
    align-items: flex-start;    
    min-height: 100vh;
    min-height: 100svh;
    padding: 0em 0em;    
    box-sizing: border-box; 
  

    .app {        
        border-radius: 0em;
        padding: 0;
        background: linear-gradient(
            360deg, 
            rgba(255, 255, 255, 1) -10%, 
            rgba(255, 255, 255, 1) 90%
        );
        box-sizing: border-box;
        
        min-height: 100vh;

        width: 100%;
        
        
        align-self: center !important;  
        
        @include app-max-width;
    }    

    .footerNote {
        align-self: center !important;

        .luv {
            position: relative;
            top:0.25em !important;
            transition: all 0.3s;
    
            &:hover {
                fill: #FF0000;
            }
        }
    
    }
}

