$app-max-width: 1800px;
$app-max-width-breakpoint: $app-max-width;
$app-max-width-over-breakpoint: 90vw;

@mixin app-max-width() {
    max-width: $app-max-width-over-breakpoint;
    @media (max-width: 1000px) {
        max-width: 100vw;
    }
    @media (min-width: $app-max-width-breakpoint) {
        max-width: $app-max-width;
    }
}