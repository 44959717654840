.description {
    padding: 1em;
}
.actions {
    padding: 0 1em 1em 1em;
}
.imageContainer {
    text-align: center;
    position: relative;

    .image {
        position: relative;
        z-index: 1;
        display: inline-block;
        width:100%;
        max-width: 360px;
        height: 360px;
        background-size: 100%;
        background-repeat: no-repeat;
        background-position-x: center; 
    }

    .manufacturerLogo {
        position: absolute;
        bottom: 0;
        right: 1em;
        z-index: 2;

        max-width: 120px;
        width: 30%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: top right;
        background-size: 100% auto;
    }
}

.preview {
    position: relative;
    
    .closeBtnContainer {
        position: absolute;
        right: 0.5em;
        top: 0.5em;
    }

    .paper {
        padding-bottom: 1em;
    }
}
.readMoreContainer {
    .button {
        text-align: right;
    }
    .expandedDescription {        
        padding-top: 1em;
    }
}