@import '../../AppVariables.module.scss';

.heading {    
    text-align: center;
    font-weight: normal;
    flex-grow: 1;
    align-self: center;
}

.navButtons {
    text-align: center; 
    margin-bottom: 0.5em !important;   
    padding-bottom: 0.5em !important; 
}

.st0 {
    fill:#171A1D;
}
.st1 {
    fill:#8FA2AE;
}
.st2 {
    fill:#B42D25;
}

.loginContainer {
    align-self: center;

    .loginBtn {
        margin-left: 0.25em !important;
    }
}

.profile {
    align-self: center;
}
.appContent {
    padding: 0.75em 0.75em 0.75em 0.75em;
}
.footer {
    padding-bottom: 0em;
    position: fixed;
    bottom: 0;
    
    width: 100%;
    z-index: 100; /* #EBEFF1 | edb151 and C92128 */

    

    @include app-max-width;
    
    // &:before {
    //     /*
    //     box-shadow: inset 0 0 2000px rgba(255, 255, 255, .015);
    //     filter: blur(5px);
    //     background: inherit;        
    //     */
    //     /*
    //     backdrop-filter: blur(8px); 
    //     box-shadow: 0px 10px 15px 10px rgb(0 0 0 / 15%);
    //     background-color: rgb(228 228 228 / 15%);
    //     */

    //     backdrop-filter: blur(8px); /* Chrome and Opera */
    //     box-shadow: inset 0 0 0 200px rgba(255,255,255,0.08);

    //     width: 100%;
    //     height: 100%;
    //     position: absolute;
    //     content: '';
    // }
    
    .buttonsContainerInFooter {
        font-size: 1em;
        position: absolute;
        top: -1em;
        left: 0;
        z-index: 110;
        width: 100%;

        button {
            position: relative;
            top: -1em;
        }
    }

    .svgContainer {        
        height: 3em;

        // background-image: url(./line.svg);
        // background-size: cover;
        // background-repeat: no-repeat;

        /*background: linear-gradient(180deg, #fffffff1 -15%, #EBEFF1f1 100%); */  
        background: linear-gradient(180deg, #fffffff1 -15%, #b8d3e6f1 100%);  
        opacity: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 
            0px -1px 0px -1px rgba(0,0,0,0.015),
            0px -2px 0px 0px rgba(0,0,0,0.015),
            0px -3px 0px 0px rgba(0,0,0,0.015)
            
            /*0px -1px 2px 0px rgba(0,0,0,0.075),
            0px -2px 2px 0px rgba(0,0,0,0.050),
            0px -3px 2px 0px rgba(0,0,0,0.025),
            
            2px 1px -1px rgba(0, 0, 0, 0.2), 
            0px 1px 1px 0px rgba(0, 0, 0, 0.14), 
            0px 1px 3px 0px rgba(0, 0, 0, 0.12)
            */
        ;
    }

    .text {
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        align-items: center;
        color: #212121;
        text-shadow: 3px 3px 5px rgba(200, 200, 200, .3);
        padding-bottom: 0.5em;        
    }

    .luv {
        position: relative;
        top:0.25em !important;
        transition: all 0.3s;

        &:hover {
            fill: #FF0000;
        }
    }
}