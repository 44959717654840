
@keyframes spin { 
    100% { 
        transform:rotate(360deg); 
    } 
}

.container {
    padding: 4em;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    .loaderContainer {        
        width: 50%;
        max-width: 410px;


        position:relative;
        top:0;
        left:0;        

        .innerRing,
        .middleRing,
        .outerRing {
            background-color:transparent;
            width: 100%;
            
            position:absolute;
            top:0;
            left:0;
            
            box-sizing: border-box;
            padding-right: 11.33%;
        }

        .innerRing {
            animation:spin 5s linear infinite; 
        }
        .middleRing {
            animation:spin 3.5s linear infinite; 
        }
        .outerRing {
            animation:spin 2s linear infinite; 
        }

        svg {
            enable-background: new 0 0 419.99 162.79;
        }
    }
}

.st0 {
    fill:#171A1D;
}
.st1 {
    fill:#8FA2AE;
}
.st2 {
    fill:#B42D25;
}