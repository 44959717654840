.name,
.avatar {
    align-self: center;
}

.avatar {
    cursor: pointer;
}
.name {
    padding-right: 0.5em;
}

.menuEMail {
    padding: 0.5em 1em 1em 1em;
    text-align: center;
}

.languageIconContainer {
    width: 1.3em;
    height: 1.3em;
    border: 1px solid #9c9c9c;
    
    display: flex;
    align-items: center;
    border-radius: 1.3em;  
    overflow: hidden;

    position: relative;
    left: -0.05em;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
    

    .languageIcon {        
        background-repeat: no-repeat;
        background-size: 2em auto;
        background-position: center center;

        width: 2em;
        height: 1.33em;
    }
    .languageIconET {
        background-image: url(../../app-images/EE.svg);
    }
    .languageIconGB {
        background-image: url(../../app-images/GB.svg);
    }
}

.settingsPanel {
    
    .container {
        padding: 0 2em;

        .heading {
            padding: 0.25em 0em;
        }
        .buttons {
            text-align: right;

            button {
                margin-left: 1em;
            }
        }
        .settings {
            margin-top: 1em;
        }
        .titleBlockHeading {
            margin-top: 1.5em;
        }
    }
}
