.area {
    font-weight: 600!important;
    font-size: 1.15em!important;
}
.areaUnit {
    font-weight: 600!important;
    font-size: 1em!important;
}
.value {
    font-weight: 600!important;
    font-size: 1.15em!important;

    svg {
        vertical-align: bottom;
    }
}