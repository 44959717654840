.unit {
    font-weight: 600!important;
    vertical-align: bottom;
    display: inline-block;
    
    padding-left: 0.5em;
    font-size: 1.1em!important;
    position: relative;
    top: -1px !important;
}
.label {
    vertical-align: top;
    display: inline-block;
    padding-top: 0.2em;
    padding-left: 0.5em;
}