.buttons {
    text-align: right;
}
.temperatureValue {
    font-weight: 600!important;
    font-size: 1em!important;
    padding-left: 0.5em;
}
.temperatureContainerCell {    
    padding-bottom: 1em;
}
.svgContainer {
    padding-top: 2em;
    padding-bottom: 2em;
}