.image {
    height: 140px;
    background-size: cover;
    background-color: #efefef;
}
.container {
    padding: 1em 1em 1em 1em;
}
.decription {
    padding-top: 1em;
}